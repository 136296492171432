<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid"
  id="kt_content"
  xmlns="http://www.w3.org/1999/html"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>

          <ul class="nav nav-pills nav-light-primary nav-bold border-0">
            <li class="nav-item">
              <a
                class="nav-link border-0 active"
                data-toggle="tab"
                href="#personalInformation"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">{{ dataValues.patient_heading }}</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="toggleTabs('zakat')">
              <a class="nav-link border-0" data-toggle="tab" href="#zakat">
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">Charity Details</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="familyCall()">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#family_info"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Family Information</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="documentsCall()">
              <a class="nav-link border-0" data-toggle="tab" href="#documents">
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Documents</span>
              </a>
            </li>

            <li class="nav-item" (click)="billingCall()" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#billing">
                <!--                <span class="nav-icon"><i class="flaticon2-hospital"></i></span>-->
                <span class="nav-text">Billing</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient" (click)="appointmentsCall()">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#appointments"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Appointments</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#clinical">
                <!--                <span class="nav-icon"><i class="flaticon2-schedule"></i></span>-->
                <span class="nav-text">Clinical</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#reports">
                <!--                <span class="nav-icon"><i class="fas fa-bed"></i></span>-->
                <span class="nav-text">Reports</span>
              </a>
            </li>
          </ul>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <div class="d-block d-sm-block d-md-none">
          <a
            class="btn btn-light-primary font-weight-bolder btn-sm"
            (click)="openPatientList()"
            >Patients</a
          >
        </div>
        <!--begin::Actions-->
        <!--end::Actions-->
        <!--begin::Dropdown-->
        <!--        <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">-->
        <!--          <a  class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--											<span class="svg-icon svg-icon-success svg-icon-2x">-->
        <!--												&lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg&ndash;&gt;-->
        <!--												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--														<polygon points="0 0 24 0 24 24 0 24" />-->
        <!--														<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />-->
        <!--														<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />-->
        <!--													</g>-->
        <!--												</svg>-->
        <!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--											</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">-->
        <!--            &lt;!&ndash;begin::Navigation&ndash;&gt;-->
        <!--            <ul class="navi navi-hover">-->
        <!--              <li class="navi-header font-weight-bold py-4">-->
        <!--                <span class="font-size-lg">Choose Label:</span>-->
        <!--                <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mb-3 opacity-70"></li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-success">Customer</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-danger">Partner</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-warning">Suplier</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-primary">Member</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-item">-->
        <!--                <a  class="navi-link">-->
        <!--														<span class="navi-text">-->
        <!--															<span class="label label-xl label-inline label-light-dark">Staff</span>-->
        <!--														</span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li class="navi-separator mt-3 opacity-70"></li>-->
        <!--              <li class="navi-footer py-4">-->
        <!--                <a class="btn btn-clean font-weight-bold btn-sm" >-->
        <!--                  <i class="ki ki-plus icon-sm"></i>Add new</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            &lt;!&ndash;end::Navigation&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Registration-->
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="personalInformation"
          role="tabpanel"
          aria-labelledby="personalInformation"
        >
          <div class="row">
            <div class="col-md-9 col-sm-12 col-12 p-lg-0 p-md-0">
              <form
                [formGroup]="PatientRegistrationForm"
                (ngSubmit)="newRegistration()"
              >
                <div class="row mb-3">
                  <div
                    class="col-lg-2 col-md-3 col-sm-12 col-12 pr-0 mb-4 mb-sm-4 mb-md-0"
                  >
                    <div class="card h-100">
                      <div class="card-body patient-img-card">
                        <div class="row justify-content-center">
                          <!--Image Upload-->
                          <div
                            class="image-input image-input-empty image-input-outline mb-4"
                            id="kt_image_5"
                            style="position: relative"
                          >
                            <img
                              *ngIf="
                                patient?.profile_image;
                                else profileImageElse
                              "
                              [src]="patient.profile_image"
                              class="patient_img"
                              style="
                                object-fit: contain;
                                position: absolute;
                                padding: 5px;
                                width: 100%;
                                height: 100%;
                              "
                            />
                            <ng-template #profileImageElse>
                              <img
                                [src]="
                                  patientImage
                                    ? patientImage
                                    : '../../../../../assets/media/users/blank.png'
                                "
                                class="patient_img"
                                style="
                                  object-fit: contain;
                                  position: absolute;
                                  padding: 5px;
                                  width: 100%;
                                  height: 100%;
                                "
                              />
                            </ng-template>

                            <div class="image-input-wrapper"></div>

                            <!--                            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">-->
                            <!--                              <i class="fa fa-pen icon-sm text-muted"></i>-->
                            <!--                              <input type="file" name="profile_avatar" (change)="fileUploadFunc($event)" accept=".png, .jpg, .jpeg"/>-->
                            <!--                              <input type="hidden" name="profile_avatar_remove"/>-->
                            <!--                            </label>-->
                          </div>
                        </div>

                        <div class="row text-center">
                          <div class="col-12">
                            <button
                              class="btn btn-primary btn-sm mb-2 button-width"
                              type="button"
                              (click)="cambox()"
                            >
                              {{ dataValues.picture }}
                            </button>
                            <br />
                            <button
                              class="btn btn-primary btn-sm button-width"
                              type="button"
                              (click)="getThumbImpression()"
                            >
                              {{ dataValues.impression }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-9 col-sm-12 col-12">
                    <div class="card">
                      <div class="card-body patient-img-card">
                        <div class="row mb-0 mx-0">
                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>{{ dataValues.title }}</label>
                              <select
                                class="form-control form-control-sm normal-height p-lr-0"
                                formControlName="title"
                                [ngClass]="{
                                  'is-invalid': submitted && f.title.errors
                                }"
                                (change)="onChange()"
                              >
                                <option value="mr">Mr</option>
                                <option value="mrs">Mrs</option>
                                <option value="miss">Miss</option>
                                <option value="bo">B/O</option>
                                <option value="do">D/O</option>
                                <option value="dr">Dr</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label
                                >{{ dataValues.first_name }}
                                <span class="text-danger">*</span></label
                              >
                              <input
                                type="text"
                                autofocus
                                [value]="
                                  PatientRegistrationForm.value.first_name
                                    | titlecase
                                "
                                formControlName="first_name"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.first_name }}"
                                [ngClass]="{
                                  'is-invalid': submitted && f.first_name.errors
                                }"
                                (keypress)="_keyPressOnlyChar($event)"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>{{ dataValues.last_name }}</label>
                              <input
                                type="text"
                                [value]="
                                  PatientRegistrationForm.value.last_name
                                    | titlecase
                                "
                                formControlName="last_name"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.last_name }}"
                                (keypress)="_keyPressOnlyChar($event)"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <span
                                *ngIf="
                                  PatientRegistrationForm.value.title === 'mrs'
                                "
                                ><label>{{ dataValues.husband }}</label></span
                              >
                              <span
                                *ngIf="
                                  PatientRegistrationForm.value.title !== 'mrs'
                                "
                                ><label>{{ dataValues.guardian }}</label></span
                              >
                              <input
                                type="text"
                                formControlName="father_name"
                                class="form-control form-control-sm"
                                [placeholder]="placeholder"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && f.father_name.errors
                                }"
                                (keypress)="_keyPressOnlyChar($event)"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label> {{ dataValues.blood_group }} </label>
                              <select
                                class="form-control form-control-sm"
                                formControlName="blood_group"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && f.blood_group.errors
                                }"
                              >
                                <option value="" [disabled]="true">
                                  {{ dataValues.select }}
                                </option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.gender }}
                                <span class="text-danger">*</span></label
                              >
                              <select
                                class="form-control form-control-sm"
                                formControlName="gender"
                                [ngClass]="{
                                  'is-invalid': submitted && f.gender.errors
                                }"
                              >
                                <option value="" [disabled]="true">
                                  {{ dataValues.select }}
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="unidentified">
                                  Unidentified
                                </option>
                                <option value="transgender">Transgender</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div style="display: flex !important">
                              <div style="width: 48%" class="mr-2">
                                <label>
                                  {{ dataValues.age }}
                                  <span class="text-danger">*</span></label
                                >
                                <select
                                  style="text-transform: capitalize"
                                  class="form-control form-control-sm"
                                  formControlName="pat_age_type"
                                  [ngClass]="{
                                    'is-invalid':
                                      submitted && f.pat_age_type.errors
                                  }"
                                  [value]="selected_age_type || 'years'"
                                  (change)="setDOBfromAge()"
                                >
                                  <option
                                    [value]="age_type"
                                    *ngFor="let age_type of age_types"
                                  >
                                    {{ age_type }}
                                  </option>
                                </select>
                              </div>

                              <div style="width: 48%">
                                <label></label>
                                <!--                                <input type="number" placeholder="Age" formControlName="pat_age_value" min="0" max="150" class="form-control form-control-sm input-transparent p-r-0" [ngModel]="age_value" (keyup)="setDOBfromAge();checkAge($event)" (keypress)="_onlyNumeric($event);checkAge($event)"-->
                                <!--                                       [ngClass]="{ 'is-invalid': submitted && f.pat_age_value.errors }">-->
                                <ng-container *ngIf="disableAgeValue === true">
                                  <input
                                    type="text"
                                    placeholder="{{ dataValues.age }}"
                                    formControlName="pat_age_value"
                                    class="form-control form-control-sm input-transparent p-r-0"
                                    [ngModel]="age_value"
                                    (keypress)="_onlyNumeric($event)"
                                    disabled
                                  />
                                </ng-container>
                                <ng-container *ngIf="disableAgeValue === false">
                                  <input
                                    type="text"
                                    formControlName="pat_age_value"
                                    min="0"
                                    max="150"
                                    class="form-control form-control-sm input-transparent p-r-0"
                                    [ngModel]="age_value"
                                    (keyup)="checkAge($event); setDOBfromAge()"
                                    (keypress)="
                                      _onlyNumeric($event); checkAge($event)
                                    "
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && f.pat_age_value.errors
                                    }"
                                  />
                                </ng-container>
                              </div>
                            </div>
                          </div>

                          <!--                          <div class="col-lg-1 col-md-4  col-sm-6 col-12">-->
                          <!--                            <div class="form-group">-->
                          <!--                              <label> Age  <span class="text-danger">*</span></label>-->
                          <!--                              <select style="text-transform: capitalize;" class="form-control form-control-sm" formControlName="pat_age_type" [ngClass]="{ 'is-invalid': submitted && f.pat_age_type.errors }" [value]="selected_age_type || 'years'" (change)="setDOBfromAge()">-->
                          <!--                                <option [value]="age_type" *ngFor="let age_type of age_types">{{age_type}}</option>-->
                          <!--                              </select>-->
                          <!--                            </div>-->
                          <!--                          </div>-->

                          <!--                          <div class="col-lg-1 col-md-4  col-sm-6 col-12">-->
                          <!--                            <div class="form-group">-->
                          <!--                              <label></label>-->
                          <!--                              <input type="number" placeholder="Age" formControlName="pat_age_value" min="0" max="150" class="form-control form-control-sm input-transparent p-r-0" [ngModel]="age_value" (keyup)="setDOBfromAge();checkAge($event)" (keypress)="_onlyNumeric($event);checkAge($event)"-->
                          <!--                                     [ngClass]="{ 'is-invalid': submitted && f.pat_age_value.errors }" >-->
                          <!--                            </div>-->
                          <!--                          </div>-->

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <span class="required"
                                ><label>
                                  {{ dataValues.date_of_birth }}
                                  <span class="text-danger">*</span></label
                                ></span
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Date Of Birth"
                                id="patientAge"
                                [ngClass]="{
                                  'is-invalid': submitted && f.dob.errors
                                }"
                                [ngModel]="patient_dob"
                                readonly="readonly"
                                formControlName="dob"
                              />
                              <!--                              <input-->
                              <!--                                type="date"-->
                              <!--                                formControlName="dob"-->
                              <!--                                class="form-control form-control-sm"-->
                              <!--                                id="patientAge"-->
                              <!--                                [ngClass]="{-->
                              <!--                                  'is-invalid': submitted && f.dob.errors-->
                              <!--                                }"-->
                              <!--                                [ngModel]="patient_dob | date: 'yyyy-MM-dd'"-->
                              <!--                                (change)="calculateAge()"-->
                              <!--                                max="{{ today }}"-->
                              <!--                              />-->
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.cnic }}
                                <span *ngIf="cnicMandatory" class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                formControlName="patient_nic"
                                id="nic"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.cnic }}"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && f.patient_nic.errors
                                }"
                                (keypress)="_onlyNumeric($event)"
                                maxlength="13"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>{{ dataValues.contact }}</label>
                              <select
                                class="form-control form-control-sm"
                                formControlName="phone1_type"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && f.phone1_type.errors
                                }"
                                (change)="setContactValidation()"
                              >
                                <option [selected]="true" value="mobile">
                                  Mobile
                                </option>
                                <option value="landline">Landline</option>
                                <option value="home">Home</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.number }}
                                <span class="text-danger">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="phone1"
                                id="phone1"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.number }}"
                                [ngClass]="{
                                  'is-invalid': submitted && f.phone1.errors
                                }"
                                (keypress)="_onlyNumeric($event)"
                                (focusout)="searchPatientByPhoneNumber()"
                                [attr.maxlength]="
                                  pattern
                                    ? null
                                    : f.phone1_type.value === 'mobile'
                                      ? 11
                                      : 25
                                "
                                [attr.minlength]="
                                  pattern
                                    ? null
                                    : f.phone1_type.value === 'mobile'
                                      ? 11
                                      : 1
                                "
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label> {{ dataValues.old_mrn }} </label>
                              <input
                                type="number"
                                formControlName="old_mrn"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.mrn }}"
                                [ngClass]="{
                                  'is-invalid': submitted && f.old_mrn.errors
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group input-margin">
                              <label> {{ dataValues.email }} </label>
                              <input
                                type="text"
                                formControlName="email"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.email }}"
                                [ngClass]="{
                                  'is-invalid': submitted && f.email.errors
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group input-margin">
                              <label> {{ dataValues.country }} </label>
                              <select
                                class="form-control form-control-sm country bootstrap-select-container"
                                data-container="body"
                                data-live-search="true"
                                formControlName="passport_country_id"
                              >
                                <option
                                  value=""
                                  [selected]="true"
                                  [disabled]="true"
                                >
                                  {{ dataValues.select }}
                                </option>
                                <ng-container
                                  *ngFor="let country of countriesList"
                                >
                                  <option [value]="country.id">
                                    {{ country.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group input-margin">
                              <label>{{ dataValues.passport }}</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.passport }}"
                                formControlName="patient_passport"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group input-margin">
                              <label>{{ dataValues.emp_sap_id }}</label>
                              <input
                                type="text"
                                id="sap_id"
                                class="form-control form-control-sm"
                                formControlName="sap_id"
                                placeholder="{{ dataValues.emp_sap_id }}"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body patient-card">
                    <div class="row">
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <!--                        [ngClass]="{-->
                        <!--                          'col-lg-2 col-md-3 col-sm-6 col-12':-->
                        <!--                            isShowClinic === false,-->
                        <!--                          'col-md-4 col-sm-10 col-12': isShowClinic === true-->
                        <!--                        }"-->
                        <!--                      >-->
                        <div class="form-group">
                          <label> {{ dataValues.address }} </label>
                          <input
                            type="text"
                            formControlName="address1"
                            class="form-control form-control-sm"
                            autocomplete="off"
                            placeholder="{{ dataValues.address }}"
                            [ngClass]="{
                              'is-invalid': submitted && f.address1.errors
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>
                            {{ dataValues.city }}
                            <span class="text-danger">*</span></label
                          >
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.city_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm city bootstrap-select-container"
                              formControlName="city_id"
                              data-container="body"
                              data-live-search="true"
                              (change)="getLocation($event.target.value, false)"
                            >
                              <option value="" [disabled]="true">
                                {{ dataValues.select }}
                              </option>
                              <ng-container *ngFor="let city of cityList">
                                <option [value]="city.id">
                                  {{ city.name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>
                            {{ dataValues.state }}
                            <span class="text-danger">*</span></label
                          >
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.state_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm state bootstrap-select-container"
                              data-container="body"
                              data-live-search="true"
                              formControlName="state_id"
                              (change)="getCity($event.target.value, false)"
                            >
                              <option value="" [disabled]="true">
                                {{ dataValues.select }}
                              </option>
                              <ng-container *ngFor="let state of stateList">
                                <option [value]="state.id">
                                  {{ state.name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.zip_code }}</label>
                          <input
                            type="text"
                            formControlName="zip_code"
                            class="form-control form-control-sm"
                            placeholder="ZIP Code"
                            (keypress)="-_onlyNumeric($event)"
                          />
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>
                            {{ dataValues.country }}
                            <span class="text-danger">*</span></label
                          >
                          <!--                               <a type="button" class="float-md-right  text-primary">-->
                          <!--                                <i class="fas fa-redo-alt icon-xs redo-btn"></i>-->
                          <!--                              </a>-->
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.country_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm mt-0 country bootstrap-select-container"
                              formControlName="country_id"
                              data-container="body"
                              data-live-search="true"
                              data-size="10"
                              (change)="
                                getState($event.target.value, false);
                                getPatternAndCountryCode(
                                  $event.target.value,
                                  false
                                )
                              "
                            >
                              <option value="" [disabled]="true">
                                {{ dataValues.select }}
                              </option>
                              <ng-container
                                *ngFor="let country of countriesList"
                              >
                                <option [value]="country.id">
                                  {{ country.name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.location }} </label>
                          <div
                            [ngClass]="{
                              'is-invalid': submitted && f.location_id.errors
                            }"
                          >
                            <select
                              class="form-control form-control-sm location bootstrap-select-container"
                              data-container="body"
                              data-live-search="true"
                              formControlName="location_id"
                            >
                              <option value="">{{ dataValues.select }}</option>
                              <ng-container
                                *ngFor="let location of locationList"
                              >
                                <option [value]="location.id">
                                  {{ location.name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-2 col-md-3 col-sm-6 col-12"
                        *ngIf="isShowClinic"
                      >
                        <div class="form-group">
                          <label>
                            {{ dataValues.clinic }}
                            <span class="text-danger">*</span></label
                          >
                          <select
                            class="form-control form-control-sm"
                            formControlName="clinic_id"
                            [ngClass]="{
                              'is-invalid': submitted && f.clinic_id.errors
                            }"
                          >
                            <option value="">{{ dataValues.select }}</option>
                            <ng-container *ngFor="let clinic of clinics">
                              <option [value]="clinic.id">
                                {{ clinic?.name }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.race }}</label>
                          <select
                            class="form-control form-control-sm"
                            id="race"
                            formControlName="race"
                            data-container="body"
                            data-live-search="true"
                            data-none-selected-text="Select"
                          >
                            <option value="">Select</option>
                            <ng-container *ngFor="let race of raceList">
                              <option [value]="race?.id">
                                {{ race?.name }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.ethnicity }}</label>
                          <select
                            class="form-control form-control-sm"
                            id="ethnicity"
                            formControlName="ethnicity"
                            data-container="body"
                            data-live-search="true"
                            data-none-selected-text="Select"
                          >
                            <option value="">Select</option>
                            <ng-container *ngFor="let race of ethnicityList">
                              <option [value]="race?.id">
                                {{ race?.name }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.patient_type }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="patient_type_id"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.patient_type_id.errors
                            }"
                            [ngModel]="private_patient_type_id"
                            (change)="onChangetype($event.target.value)"
                            id="selected_type"
                          >
                            <option value="" [disabled]="true">
                              {{ dataValues.select }}
                            </option>
                            <option
                              [value]="patient_type.id"
                              *ngFor="let patient_type of patient_types"
                            >
                              {{ patient_type.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.company }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="company_id"
                            [ngClass]="{
                              'is-invalid': submitted && f.company_id.errors
                            }"
                            (change)="fetch_company_groups($event.target.value)"
                            [ngModel]="privete_company_id"
                          >
                            <option value="" [disabled]="true">
                              {{ dataValues.select }}
                            </option>
                            <option
                              [value]="company.id"
                              *ngFor="let company of companies"
                            >
                              {{ company.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.group }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="group_id"
                            [ngClass]="{
                              'is-invalid': submitted && f.group_id.errors
                            }"
                            [ngModel]="private_group_id"
                          >
                            <option value="" [disabled]="true">
                              {{ dataValues.select }}
                            </option>
                            <option
                              [value]="group.id"
                              *ngFor="let group of groups"
                            >
                              {{ group.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.primary_mrn }}</label>
                          <input
                            type="text"
                            formControlName="family_mrn"
                            class="form-control form-control-sm"
                            placeholder="Guardian MRN"
                            [ngClass]="{
                              'is-invalid': submitted && f.family_mrn.errors
                            }"
                            (change)="familyPatient($event)"
                            maxlength="8"
                            (keypress)="_onlyNumeric($event)"
                          />
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.primary_name }}</label>
                          <input
                            type="text"
                            formControlName="family_name"
                            class="form-control form-control-sm"
                            placeholder="Guardian Name"
                            [readonly]="true"
                            [ngModel]="family_patient"
                          />
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.rel_with_patient }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="guardian_relationship"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.guardian_relationship.errors
                            }"
                          >
                            <option value="" [disabled]="true">
                              {{ dataValues.select }}
                            </option>
                            <ng-container *ngFor="let lov of lookups">
                              <option
                                [value]="lov.key"
                                *ngIf="lov.category === 'relationship'"
                              >
                                {{ lov.key }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="form-group m-0">
                          <div class="checkbox-inline">
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                class="guardian filter"
                                (click)="onChangeGuardian()"
                                #chkGaudian
                                [checked]="isGuardian"
                              />
                              <span class="mr-2"></span
                              >{{ dataValues.guardian }}
                            </label>

                            <label class="checkbox">
                              <input
                                type="checkbox"
                                formControlName="mlc"
                                class="mlc filter"
                                (click)="onChangeMLC($event)"
                                #chkMLC
                                [checked]="isMLC"
                              />
                              <span class="mr-2"></span>{{ dataValues.mlc }}
                            </label>
                          </div>
                          <!--                    <label class="margin-top-15px">-->
                          <!--                      <input type="checkbox" class="guardian margin-right-5 filter" (click)='onChangeGuardian()' #chkGaudian  [checked]="isGuardian">Guardian</label>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.employment_status }}</label>
                          <select
                            formControlName="employment_status"
                            class="form-control form-control-sm"
                          >
                            <option value="">Select</option>
                            <option
                              *ngFor="let data of employeeStatuses"
                              [value]="data?.value"
                            >
                              {{ data?.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.account_status }}</label>
                          <select
                            formControlName="account_status"
                            class="form-control form-control-sm"
                          >
                            <option
                              *ngFor="let data of accountStatus"
                              [value]="data?.value"
                            >
                              {{ data?.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>
                            {{ dataValues.written_contact_preference }}</label
                          >
                          <select
                            formControlName="written_contact_preference"
                            class="form-control form-control-sm"
                          >
                            <option
                              *ngFor="let data of writtenContactPreference"
                              [value]="data?.value"
                            >
                              {{ data?.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--        Alternate Contact-->
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.alternate_contact }}</label>
                          <input
                            type="text"
                            id="alternate_contact"
                            placeholder="Alternate Contact"
                            class="form-control form-control-sm"
                            formControlName="alternate_contact"
                            maxlength="14"
                            (keypress)="_onlyNumeric($event)"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.ssn }}</label>
                          <input
                            type="text"
                            id="ssn"
                            maxlength="11"
                            class="form-control form-control-sm"
                            formControlName="ssn"
                            placeholder="{{ dataValues.ssn }}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isGuardian" class="card mt-3">
                  <div class="card-body patient-card">
                    <div class="row">
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.guardian_name }} </label>
                          <input
                            type="text"
                            formControlName="guardian_first_name"
                            placeholder="{{ dataValues.guardian_name }}"
                            class="form-control-sm form-control input-transparent"
                            [readonly]="!chkGaudian.checked"
                            (keypress)="_keyPressOnlyChar($event)"
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.relation }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="guardian_relationship"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.guardian_relationship.errors
                            }"
                          >
                            <option value="" [disabled]="true">
                              {{ dataValues.select }}
                            </option>
                            <ng-container *ngFor="let lov of lookups">
                              <option
                                [value]="lov.key"
                                *ngIf="lov.category == 'relationship'"
                              >
                                {{ lov.key }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.cnic }} </label>
                          <input
                            type="text"
                            formControlName="guardian_nic"
                            placeholder="{{ dataValues.guardian_cnic }}"
                            class="form-control-sm form-control input-transparent"
                            (keypress)="_onlyNumeric($event)"
                            maxlength="13"
                            [readonly]="!chkGaudian.checked"
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.city }} </label>
                          <input
                            type="text"
                            formControlName="guardian_near_by_city"
                            [readonly]="true"
                            class="form-control form-control-sm"
                            placeholder="{{ dataValues.city }}"
                            [readonly]="!chkGaudian.checked"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.address }} </label>
                          <input
                            type="text"
                            formControlName="guardian_address"
                            class="form-control"
                            placeholder="{{ dataValues.address }}"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.guardian_address.errors
                            }"
                            [readonly]="!chkGaudian.checked"
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.contact }} </label>
                          <input
                            type="text"
                            formControlName="guardian_phone"
                            class="form-control"
                            placeholder="{{ dataValues.contact }}"
                            [ngClass]="{
                              'is-invalid': submitted && f.guardian_phone.errors
                            }"
                            (keypress)="_onlyNumeric($event)"
                            maxlength="11"
                            [readonly]="!chkGaudian.checked"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isMLC" class="card mt-3">
                  <div class="card-body patient-card">
                    <div class="row">
                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.police }} </label>
                          <input
                            type="text"
                            [readonly]="!chkMLC.checked"
                            (keypress)="_keyPressOnlyChar($event)"
                            formControlName="policeman_name"
                            pattern="[a-zA-Z- _]+"
                            placeholder="{{ dataValues.police }}"
                            class="form-control-sm form-control input-transparent"
                          />
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.belt_number }} </label>
                          <input
                            type="text"
                            placeholder="{{ dataValues.belt_number }}"
                            formControlName="belt_number"
                            class="form-control-sm form-control input-transparent"
                            [readonly]="!chkMLC.checked"
                          />
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.police_station }} </label>
                          <input
                            type="text"
                            formControlName="police_station_number"
                            (keypress)="_onlyNumeric($event)"
                            pattern="[0-9]+"
                            placeholder="{{ dataValues.police_station }}"
                            class="form-control-sm form-control input-transparent"
                            [readonly]="!chkMLC.checked"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-6">
                  <div class="col-12 text-right">
                    <!--              <button class="btn btn-danger" type="button" (click)="openModal(newPatientModal)">open</button>-->

                    <!--              <button class="btn btn-danger" type="button" (click)="openModal1(conformPatientAdmissionModal)">open 2</button>-->

                    <!--              <button type="button" class="btn btn-sm btn-primary mrgn-5 " (click)="opdPage()">OPD</button>-->
                    <!--              <button type="button" class="btn btn-success mrgn-5 btn-fix" (click)='printPrescription()'  [disabled]="!patient || patient?.perscription_status === false">Print</button>-->
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      (click)="radiologyServicesModal()"
                    >
                      See Radiologist Worklist
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      (click)="addServices(patient)"
                      accesskey="z"
                      [disabled]="!patient || isPatientDeceased"
                    >
                      Invoicing
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                      accesskey="u"
                      (click)="updatePatient()"
                      *ngIf="isShowUpDateButton === true"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      (click)="clearFormConfirm()"
                      accesskey="c"
                    >
                      Refresh
                    </button>
                    <button
                      type="submit"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      accesskey="r"
                      [disabled]="patient || isDisable"
                    >
                      Save
                    </button>

                    <!--              <button type="button" class="btn btn-primary">Clear</button>-->
                    <!--              <button type="submit" class="btn btn-primary ml-3">Submit</button>-->
                  </div>
                  <div class="col-12 text-right mt-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                      accesskey="d"
                      (click)="discountPage(patient, 'patient/adjustments-v2')"
                      *ngIf="showAdjustmentButton"
                    >
                      Adjustments
                    </button>
                    <!-- <button type="button" id='service-btn' class="btn btn-success mrgn-5 btn-fix" [disabled]="true" accesskey="r">Reports</button> -->
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient || isPatientDeceased"
                      accesskey="a"
                      (click)="admissionPage(patient, 'patient/admission-v2')"
                    >
                      Admission
                    </button>
                    <!--                    <button type="button" class="btn btn-sm btn-primary ml-2 button-width" [disabled]="!patient"><a (click)="printCard()" class="text-white a-hover" accesskey="h">Health Card</a></button>-->
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                    >
                      <a
                        (click)="printCard()"
                        class="text-white a-hover"
                        accesskey="h"
                        >Health Card</a
                      >
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                    >
                      <a
                        (click)="print('print_wrist_band')"
                        class="text-white a-hover"
                        accesskey="w"
                        >Wrist Band</a
                      >
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="col-md-3 col-sm-12 col-12 mt-10 mt-sm-10 mt-md-0 mt-lg-0 d-none d-sm-none d-md-flex"
            >
              <div class="card clearfix">
                <div class="card-body patient-card-listing">
                  <form
                    [formGroup]="PatientSearchForm"
                    (ngSubmit)="fetchPatients()"
                  >
                    <div class="row">
                      <div class="col-12 mb-4">
                        <select
                          class="form-control form-control-sm"
                          formControlName="type"
                          [(ngModel)]="PatientSearchForm.value.type"
                          (change)="checkPatientType()"
                        >
                          <option value="default">{{ dataValues.all }}</option>
                          <option value="mrn">MRN</option>
                          <option value="name" *ngIf="isShowFilterName">
                            {{ dataValues.name }}
                          </option>
                          <option value="phone1">{{ dataValues.phone }}</option>
                          <option value="patient_nic">
                            {{ dataValues.cnic }}
                          </option>
                          <option value="sap_id">SAP ID</option>
                          <option value="thumb">THUMB</option>
                        </select>
                      </div>

                      <div class="col-12 p-lr-0 button-wrapper">
                        <input
                          type="text"
                          formControlName="search_query"
                          id="patient_search_here"
                          placeholder="Search patient here"
                          class="form-control form-control-sm input-transparent"
                        />
                        <button class="btn btn-primary btn-sm" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="mt-3 patient-listing">
                    <!--                *ngFor="let requests of allOperationRequests"-->
                    <ng-container
                      *ngFor="let patient of patients; let i = index"
                    >
                      <!--begin::Item-->
                      <!--                  align-items-center-->
                      <div
                        class="d-flex py-3 patient-panel"
                        (click)="fetchPatientInfo(patient, i, true)"
                      >
                        <!--begin::Symbol-->
                        <div class="symbol symbol-40 symbol-light-success mr-5">
                          <span class="symbol-label">
                            <img
                              style="border-radius: 12%; width: 40px"
                              *ngIf="
                                patient?.profile_image !== '';
                                else profileImageNot
                              "
                              src="{{ patient?.profile_image }}"
                              class="h-100 align-self-end"
                              alt=""
                            />
                            <ng-template #profileImageNot>
                              <img
                                *ngIf="patient?.gender === 'transgender'"
                                src="assets/media/users/blank.png"
                                class="h-100 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'unidentified'"
                                src="assets/media/users/blank.png"
                                class="h-100 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'male'"
                                src="assets/media/svg/avatars/009-boy-4.svg"
                                class="h-75 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'female'"
                                src="assets/media/svg/avatars/002-girl.svg"
                                class="h-75 align-self-end"
                                alt=""
                              />
                            </ng-template>
                          </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Text-->
                        <!--            flex-grow-1-->
                        <div
                          class="d-flex flex-column flex-grow-1 font-weight-bold"
                        >
                          <!--              text-hover-primary-->
                          <a class="text-dark mb-1 font-size-lg">{{
                            patient?.first_name + " " + patient?.last_name
                          }}</a>
                          <div class="d-inline">
                            <span class="text-muted mr-4">{{
                              patient?.mrn
                            }}</span>
                            <!--                            <span class="text-muted mr-4">{{patient?.birth_year + '-' + patient?.birth_month  + '-' + patient?.birth_day | age}}</span>-->
                            <span class="text-muted mr-4">{{
                              patient?.patient_type?.name | titlecase
                            }}</span>
                            <!--                        / {{patient?.gender | titlecase}}-->
                            <!--                            <span class="text-muted mr-4">{{patient?.age}}</span>-->
                          </div>
                        </div>

                        <!--                    badges start-->
                        <!--                    <div class=" d-flex flex-column">-->
                        <!--                <span *ngIf="requests?.urgency?.name === 'Urgent'" class="text-muted float-right">-->
                        <!--                <span class="label label-inline label-lg label-light-danger font-weight-bold py-2">{{ requests?.urgency?.name | titlecase}}</span>-->
                        <!--              </span>-->

                        <!--                      <span *ngIf="requests?.urgency?.name === 'Normal'" class="text-muted float-right">-->
                        <!--                <span class="label label-inline label-lg label-light-primary font-weight-bold py-2">{{ requests?.urgency?.name | titlecase}}</span>-->
                        <!--              </span>-->
                        <!--                    </div>-->
                        <!--                    badges end-->

                        <!--end::Text-->
                      </div>
                      <!--end::Item-->
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="billing"
          role="tabpanel"
          aria-labelledby="billing"
          *ngIf="billingTab === true"
        >
          <app-services-history [patient]="patient"></app-services-history>
        </div>
        <div
          class="tab-pane fade"
          id="zakat"
          role="tabpanel"
          aria-labelledby="zakat"
          *ngIf="zakatEligibility"
        >
          <app-zakat [patient]="patient"></app-zakat>
        </div>
        <div
          class="tab-pane fade"
          id="family_info"
          role="tabpanel"
          aria-labelledby="family_info"
          *ngIf="familyTab"
        >
          <app-family-information [patient]="patient"></app-family-information>
        </div>
        <div
          class="tab-pane fade"
          id="documents"
          role="tabpanel"
          aria-labelledby="family_info"
          *ngIf="documentsTab"
        >
          <app-patient-documents
            [patientData]="patient"
          ></app-patient-documents>
        </div>
        <div
          class="tab-pane fade"
          id="appointments"
          role="tabpanel"
          aria-labelledby="appointments"
          *ngIf="appointmentsTab"
        >
          <app-booking-slot-appointments
            [patient]="patient"
          ></app-booking-slot-appointments>
        </div>

        <div
          class="tab-pane fade"
          id="clinical"
          role="tabpanel"
          aria-labelledby="clinical"
        >
          clinical
        </div>

        <div
          class="tab-pane fade"
          id="reports"
          role="tabpanel"
          aria-labelledby="reports"
        >
          reports
        </div>
      </div>

      <!--end::Ot Registration-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--begin::Quick Cart-->
<div
  id="patient-list"
  class="offcanvas offcanvas-right p-5 d-block d-sm-block d-md-none"
>
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form [formGroup]="PatientSearchForm" (ngSubmit)="fetchPatients()">
        <div class="row">
          <div class="col-12 mb-4">
            <select
              class="form-control form-control-sm"
              formControlName="type"
              [(ngModel)]="PatientSearchForm.value.type"
            >
              <option value="default">All</option>
              <option value="mrn">MRN</option>
              <option value="name">Name</option>
              <option value="phone1">Phone</option>
              <option value="patient_nic">CNIC</option>
              <option value="sap_id">SAP ID</option>
            </select>
          </div>

          <div class="col-12 p-lr-0 button-wrapper">
            <input
              type="text"
              formControlName="search_query"
              placeholder="Search patient here"
              class="form-control form-control-sm input-transparent"
            />
            <button class="btn btn-primary btn-sm" type="submit">Search</button>
          </div>
        </div>
      </form>

      <div class="mt-8 patient-sidebar-listing">
        <!--                *ngFor="let requests of allOperationRequests"-->
        <ng-container *ngFor="let patient of patients; let i = index">
          <!--begin::Item-->
          <!--                  align-items-center-->
          <div
            class="d-flex py-3 patient-panel"
            (click)="fetchPatientInfo(patient, i, true)"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-success mr-5">
              <span class="symbol-label">
                <img
                  *ngIf="patient?.profile_image !== ''; else profileImageNot"
                  src="{{ patient?.profile_image }}"
                  class="h-100 align-self-end"
                  alt=""
                />
                <ng-template #profileImageNot>
                  <img
                    *ngIf="patient?.gender === 'transgender'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'unidentified'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'male'"
                    src="assets/media/svg/avatars/009-boy-4.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'female'"
                    src="assets/media/svg/avatars/002-girl.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                </ng-template>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <!--            flex-grow-1-->
            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <!--              text-hover-primary-->
              <a class="text-dark mb-1 font-size-lg">{{
                patient?.first_name + " " + patient?.last_name
              }}</a>
              <div class="d-inline">
                <span class="text-muted mr-4">{{ patient?.mrn }}</span>
                <!--                        / {{patient?.gender | titlecase}}-->
                <span class="text-muted mr-4">{{ patient?.age }}</span>
              </div>
              <span class="text-muted mr-4">{{
                patient?.patient_type?.name | titlecase
              }}</span>
            </div>

            <!--                    badges start-->
            <!--                    <div class=" d-flex flex-column">-->
            <!--                <span *ngIf="requests?.urgency?.name === 'Urgent'" class="text-muted float-right">-->
            <!--                <span class="label label-inline label-lg label-light-danger font-weight-bold py-2">{{ requests?.urgency?.name | titlecase}}</span>-->
            <!--              </span>-->

            <!--                      <span *ngIf="requests?.urgency?.name === 'Normal'" class="text-muted float-right">-->
            <!--                <span class="label label-inline label-lg label-light-primary font-weight-bold py-2">{{ requests?.urgency?.name | titlecase}}</span>-->
            <!--              </span>-->
            <!--                    </div>-->
            <!--                    badges end-->

            <!--end::Text-->
          </div>
          <!--end::Item-->
        </ng-container>
      </div>
    </div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer">
      <!--      <div class="d-flex align-items-center justify-content-between mb-4">-->
      <!--        <span class="font-weight-bold text-muted font-size-sm mr-2">Total</span>-->
      <!--        <span class="font-weight-bolder text-dark-50 text-right">$1840.00</span>-->
      <!--      </div>-->
      <!--      <div class="d-flex align-items-center justify-content-between mb-7">-->
      <!--        <span class="font-weight-bold text-muted font-size-sm mr-2">Sub total</span>-->
      <!--        <span class="font-weight-bolder text-primary text-right">$5640.00</span>-->
      <!--      </div>-->
      <!--      <div class="text-right">-->
      <!--        <button type="button" class="btn btn-primary text-weight-bold">Place Order</button>-->
      <!--      </div>-->
    </div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->

<!--New Register Patient Modal-->
<div class="example-preview">
  <div>
    <ng-template #newPatientModal let-c="close" let-d="dismiss">
      <form>
        <div class="modal-header p-3">
          <h4 class="modal-title">Add Services</h4>
          <a class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div>{{ message }}<br /></div>
        </div>
        <div class="modal-footer p-3">
          <a class="btn btn-sm btn-primary" (click)="c('Close click')">Close</a>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (submit)="addServices(patient)"
            (click)="addServices(patient)"
          >
            Add Service
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Confirm pateint Admission Modal-->
<div class="example-preview">
  <div>
    <ng-template #conformPatientAdmissionModal let-c="close" let-d="dismiss">
      <form>
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <a
            class="close cursor-pointer"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div>
            Patient already admitted. Are you sure you sure you want to
            proceed?<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <a class="btn btn-sm btn-primary" (click)="c('Close click')">Close</a>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (submit)="admissionPageConform(patient, 'patient/admission-v2')"
            (click)="admissionPageConform(patient, 'patient/admission-v2')"
          >
            Confirm
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Clear Confirmation Modal-->
<div class="example-preview">
  <div>
    <ng-template #clearConfirmModal let-c="close" let-d="dismiss">
      <form>
        <div class="">
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              class="close cursor-pointer"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <!--          <label class="checkbox checkbox-sm">-->
            <!--            <input type="checkbox" [(ngModel)]="isClear">-->
            <!--            <span class="mr-2"></span>-->
            <!--            Are you sure you sure you want to clear the fields?-->
            <!--          </label>-->
            <div>Are you sure you sure you want to clear the fields?</div>
          </div>
          <div class="modal-footer p-3">
            <a class="btn btn-sm btn-light-primary" (click)="d('Cross click')"
              >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-light-primary"
              (submit)="resetPatientRegistrationForm()"
              (click)="resetPatientRegistrationForm()"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Image Modal-->
<div class="example-preview">
  <div>
    <ng-template #imageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Capturing the Image!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div>
            <div *ngIf="showImage === false">
              <webcam
                [height]="199.99999999999997"
                [width]="266.66666666666663"
                [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"
              ></webcam>
            </div>
            <img
              [src]="webcamImage.imageAsDataUrl"
              style="width: 266px; height: 200px"
              class=""
              *ngIf="showImage === true"
              alt=""
            />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === false"
            (click)="triggerSnapshot()"
          >
            Take A Image
          </button>
          <!--        <button class="btn btn-sm btn-primary" *ngIf="showImage === false" (click)="toggleWebcam();">Toggle Webcam</button>-->
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="resetImageBox()"
          >
            Reset
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="captureImageComplete()"
          >
            Done
          </button>
          <!--          <button type="button" class="btn btn-sm btn-primary" (click)="c('Close click');">Close</button>-->
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="patientFoundAgainstNumberModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <form (ngSubmit)="patientFoundAgainstNumber()">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Patient is already register against this phone number</p>
        </div>
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
            >Close</a
          >
          <button
            type="button"
            class="btn btn-primary font-weight-bold"
            id="patientFoundAgainstNumberBtn"
            (submit)="patientFoundAgainstNumber()"
            (click)="patientFoundAgainstNumber()"
          >
            See Patient
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!--Radiology Worklist Modal Start-->
<div
  class="modal fade"
  id="radiologyWorkListModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Doctor's Occupancy</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>Sub Department</label>
              <select
                class="form-control form-control-sm bootstrap-select-container" id="radDepartment" data-live-search="true" data-none-selected-text="All" data-container="body" [(ngModel)]="radiologySubDepartment"
              >
                <option value="">All</option>
                <option
                  *ngFor="let t of radiologySubDepartmentData"
                  [value]="t.id"
                >
                  {{ t.title }}
                </option>
              </select>
            </div>
            </div>
            <div class="col-2" style="margin-top: 19px">
              <button type="button" class="btn btn-sm btn-primary" (click)="searchRadiologyServices()">Search</button>
            </div>
          </div>
<!--          Listing-->
          <div class="row mt-4" style=" margin-right: -100px!important;margin-left: 57px!important;">
            <ng-container  *ngFor="let data of radiologyServicesListing">
                <div class="col-2 mt-4">
              <span class="d-block font-weight-bold">
                {{ data?.emp_name }}
              </span>
                  <span>
                {{ data?.emp_dept_name }}
              </span>
                </div>
                <div class="col-4 mt-4">
              <span class="d-block">
                Pending: {{ data?.pending_services }}
              </span>
                  <span>
                Reported: {{ data?.reported_services }}
              </span>
                </div>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-sm btn-secondary font-weight-bold"
            data-dismiss="modal"
          >Close</a
          >
        </div>
      </div>
  </div>
</div>
<!--Radiology Worklist Modal End-->
