import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { PatientRoutingModule } from './patient-routing.module';
import { LocationService } from '../../services/location.service';
import { PatientService } from '../../services/patient.service';
import { CompanyService } from '../../services/company.service';
import { ServiceService } from '../../services/service.service';
import { HospitaldoctorService } from '../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { PricingService } from '../../services/pricing.service';
import { LookupService } from '../../services/lookup.service';
import { PatientTypeService } from '../../services/patient-type.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HospitalService } from '../../services/hospital.service';
import { PatientAdmissionService } from '../../services/patient-admission.service';
import { WardService } from '../../services/ward.service';
import { WebcamModule } from 'ngx-webcam';
import { AdmissionHistoryComponent } from './admission-history/admission-history.component';
import { OpdService } from '../../services/opd.service';
import { LabTestService } from '../../services/lab-test.service';
import { ServiceCancelComponent } from './service-cancel/service-cancel.component';
import { DischargeCertificateComponent } from './discharge-certificate/discharge-certificate.component';
import { PdfServicesService } from '../../services/pdf-services.service';
import { RegistrationV2Component } from './registration-v2/registration-v2.component';
import { OtIssueItemsService } from '../../services/ot-issue-items.service';
import { PrintIssueItemComponent } from './print-issue-item/print-issue-item.component';
import { SystemControlsService } from '../../services/system-controls.service';
import { EmployeeVisitService } from '../../services/employee-visit.service';
import { ServicesNewComponent } from './services-new/services-new.component';
import { FinanceConfigurationService } from '../../services/finance-configuration.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LanguageService } from '../../services/language.service';
import { PatientDiscountV2Component } from './patient-discount-v2/patient-discount-v2.component';
import { AdmissionV2Component } from './admission-v2/admission-v2.component';
import { PatientDiscountApprovalV2Component } from './patient-discount-approval-v2/patient-discount-approval-v2.component';
import { PatientMedicineDetailComponent } from './patient-medicine-detail/patient-medicine-detail.component';
import { IssueItemV2Component } from './issue-item-v2/issue-item-v2.component';
import { PatientDiscountV3Component } from './patient-discount-v3/patient-discount-v3.component';
import { WorkerComponent } from './worker/worker.component';
import { RouteShareService } from '../../services/route-share.service';
import { UserService } from '../../services/user.service';
import { ProductCatalogService } from '../../services/product-catalog.service';
import { ServicesV2Component } from './services-v2/services-v2.component';
import { ShareDataService } from '../../services/share-data.service';
import { ConfiguratorsService } from '../../services/configurators.service';
import { InventoryGeneralSettingsService } from '../../services/inventory-general-settings.service';
import { StoreManagementService } from '../../services/store-management.service';
import { TemplatesService } from '../../services/templates.service';
import { PatientMedicineDetailV2Component } from './patient-medicine-detail-v2/patient-medicine-detail-v2.component';
import { FamilyInformationComponent } from './family-information/family-information.component';
import { PatientDocumentsComponent } from './patient-documents/patient-documents.component';
import { CustomPipeModuleModule } from '../../custom-pipe-module/custom-pipe-module.module';
import { HealthCardComponent } from './health-card/health-card.component';
import { BookingSlotAppointmentsComponent } from './booking-slot-appointments/booking-slot-appointments.component';
import { ReservationManagmentServiceService } from '../../services/reservation-managment-service.service';
import { ThumbImpressionComponent } from './thumb-impression/thumb-impression.component';
import { PrintIssueItemV2Component } from './print-issue-item-v2/print-issue-item-v2.component';
import { ZakatComponent } from './zakat/zakat.component';
import { BloodBankService } from '../../services/blood-bank.service';
import { SystemGeneralControls } from '../../services/Resolver/prescriptionDataResolver';
import { ServicesNewOldComponent } from './services-new-old/services-new-old.component';
import {AgePipe} from '../../custom-pipes/age.pipe';
import { RegistrationV3Component } from './registration-v3/registration-v3.component';
import {LoanService} from '../../services/loan.service';
import {SharedModule} from '../shared-modules/shared.module';
import {FinanceControlsService} from '../../services/finance-controls.service';

@NgModule({
  declarations: [
    AdmissionHistoryComponent,
    ServiceCancelComponent,
    DischargeCertificateComponent,
    RegistrationV2Component,
    PrintIssueItemComponent,
    ServicesNewComponent,
    PatientDiscountV2Component,
    AdmissionV2Component,
    PatientDiscountApprovalV2Component,
    PatientMedicineDetailComponent,
    IssueItemV2Component,
    PatientDiscountV3Component,
    WorkerComponent,
    ServicesV2Component,
    PatientMedicineDetailV2Component,
    FamilyInformationComponent,
    PatientDocumentsComponent,
    HealthCardComponent,
    BookingSlotAppointmentsComponent,
    ThumbImpressionComponent,
    PrintIssueItemV2Component,
    ZakatComponent,
    ServicesNewOldComponent,
    RegistrationV3Component,
  ],
  imports: [
    CommonModule,
    PatientRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    WebcamModule,
    SharedModule,
    CustomPipeModuleModule,
  ],
    exports: [PatientDiscountV2Component, BookingSlotAppointmentsComponent],
  providers: [
    SystemGeneralControls,
    LocationService,
    PatientService,
    CompanyService,
    ServiceService,
    TitleCasePipe,
    HospitaldoctorService,
    DepartmentsbyhospitalService,
    PricingService,
    LookupService,
    PatientTypeService,
    HospitalService,
    PatientAdmissionService,
    WardService,
    OpdService,
    LabTestService,
    PdfServicesService,
    OtIssueItemsService,
    SystemControlsService,
    EmployeeVisitService,
    FinanceConfigurationService,
    ConfigurationService,
    LanguageService,
    RouteShareService,
    UserService,
    ProductCatalogService,
    ShareDataService,
    ConfiguratorsService,
    InventoryGeneralSettingsService,
    StoreManagementService,
    TemplatesService,
    ReservationManagmentServiceService,
    LookupService,
    ServiceService,
    BloodBankService,
    AgePipe,
    LoanService,
    FinanceConfigurationService,
    FinanceControlsService,
  ],
})
export class PatientModule {}
