import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalHospitalMaintenanceService } from '../../../services/external-hospital-maintenance.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { LabParameterService } from '../../../services/lab-parameter.service';
import { LinkLabParameterService } from '../../../services/link-lab-parameter.service';
import { isArray } from 'rxjs/internal-compatibility';
import { compareSegments } from '@angular/compiler-cli/ngcc/src/sourcemaps/segment_marker';
import { ShareDataService } from '../../../services/share-data.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-lab-test-v2',
  templateUrl: './lab-test-v2.component.html',
  styleUrls: ['./lab-test-v2.component.css'],
})
export class LabTestV2Component implements OnInit {
  @ViewChild('commentForm') commentForm: ElementRef;
  @ViewChild('selectionForm') selectionForm: ElementRef;

  departments = [];
  listing = [];
  pageDataList: any;
  pageDataListForListing: any;
  labTestSubmitted = false;
  reportingTime = {
    type: 'minutes',
    value: '',
    id: '',
  };
  ehmList = [];
  addLabTest = true;
  isOpenModal = true;
  labConfigurations = [];
  labHeadingConfigurations = [];
  labTestDescription = {
    special_instructions_show_top: false,
    special_instructions_name: '',
    special_instructions: '',
    protocol_show_top: false,
    protocol_name: '',
    protocol: '',
    methodology_show_top: false,
    methodology_name: '',
    methodology: '',
    performed_method_show_top: false,
    performed_method_name: '',
    performed_method: '',
    interpretation_show_top: false,
    interpretation_name: '',
    interpretation: '',
    comments_show_top: false,
    comments_name: '',
    comments: '',
  };
  labId: any;
  page = 1;
  totalPages: any;
  perPage = 25;
  testCode = '';
  testName = '';
  LabTestForm: FormGroup;
  updateId: any;
  labParameters: any;
  LabParameterForm: FormGroup;

  labParameter: any;
  searchSubmitted = false;
  savedLabParameters = [];
  labTest: any;
  showParameterModalData = false;
  testId: number;
  IsPreviouslyEdit = false;
  isAddParameterNew = false;
  fetchLabdatafromChild: any;
  isUpdated: any;
  isUpdateParameter = false;
  isArray = true;
  toUpdateLabTest: any;
  assessInvestigationID: any;
  returnObjectParameter: any;
  returnUpdate: any;
  isEditParameter = false;
  indexOfLabparameter: any;
  report_type: any;
  previous_result = false;
  modalHiden = true;
  parameterComment = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private ref: ChangeDetectorRef,
    private labTestService: LabTestService,
    private modalService: NgbModal,
    private labParameterService: LabParameterService,
    private ehmService: ExternalHospitalMaintenanceService,
    private departmentsByHospitalService: DepartmentsbyhospitalService,
    private labService: LabconfigurationService,
    private linkLabParameterService: LinkLabParameterService,
    private sharedService: ShareDataService,
  ) {}
  onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
  linkLabParameters(): any {
    for (let i = 0; i < this.labParametersArray.value.length; i++) {
      if (this.labParametersArray.value[i].sort_order === null) {
        toastr.error('Add Sort order');
        return;
      }
    }
    if (this.checkIfArrayIsUnique(this.labParametersArray.value) === false) {
      toastr.error('Sort order can be unique');
      return;
    }
    this.parameterComment = $('#testDescription').summernote('code');
    this.ngxLoader.start();
    this.linkLabParameterService
      .linkLabParametersV2(
        this.labParametersArray.value,
        this.parameterComment,
        this.testId,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Successfully linked Parameters');

          $('#addParameterModal').modal('hide');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  setLabParameters(data, type): any {
    let parameterType = data.parameter_type;
    if (data.parameter_type === 'combo') {
      console.log('data', data);
      parameterType =
        data.lab_assess_ranges[0].report_type_1 === 'decimal'
          ? 'Number'
          : data.lab_assess_ranges[0].report_type_1;
    }
    return this.formBuilder.group({
      id: [data.id],
      title: [data.title],
      parameter_code: [data.parameter_code],
      is_active: [data.is_active],
      uom: [data.uom],
      specimen: [data.specimen],
      result_type1: [data.result_type1],
      result_type2: [data.result_type2],
      parameter_type_new: [parameterType],
      parameter_type: [data.parameter_type],
      lab_configuration_id: [data.lab_configuration_id || ''],
      primary_id: [data.primary_id || ''],
      is_add: [type],
      sort_order: [data.sort_order, Validators.required],
    });
  }
  savedLabParametersArray(): any {
    while (this.labParametersArray.length) {
      this.labParametersArray.removeAt(0);
    }
    for (let j = 0; j < this.savedLabParameters.length; j++) {
      this.labParametersArray.push(
        this.setLabParameters(this.savedLabParameters[j], false),
      );
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 200);
  }
  // this array is for ading the paramets using Copy format Feild
  savedLabParametersArrayForMoreParameters(): any {
    for (let j = 0; j < this.savedLabParameters.length; j++) {
      this.labParametersArray.push(
        this.setLabParameters(this.savedLabParameters[j], true),
      );
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 200);
  }

  fetchLabTest(id, assess_inv): any {
    setTimeout(() => {
      $('#labTestsPerameter').selectpicker('refresh');
      $('#copyParameter').selectpicker('refresh');
    }, 100);
    this.testId = id;
    this.assessInvestigationID = assess_inv;
    this.parameterComment = '';
    this.savedLabParameters = [];
    this.linkLabParameterService.searchLabTestV2(id).subscribe(
      (data) => {
        this.labTest = data.lab_investigation;
        // this.parameterComment = this.labTest.parameter_comments || '';
        $('#testDescription').summernote(
          'code',
          this.labTest.parameter_comments,
        );
        this.savedLabParameters = data.lab_assessments;
        $('#addParameterModal').modal('show');
        if (this.savedLabParameters.length > 0) {
          this.savedLabParametersArray();
          this.isArray = true;
        } else {
          this.isArray = false;
        }
        this.ref.markForCheck();
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#labTestsPerameter').selectpicker('refresh');
          $('#labTestsPerameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
          $('#copyParameter').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  // fetch lab function is for adding the modal
  fetchLabTestAddingParameters(id): any {
    setTimeout(() => {
      $('#labTestsPerameter').selectpicker();
      $('#labTestsPerameter').selectpicker('refresh');
      $('#copyParameter').selectpicker();
      $('#copyParameter').selectpicker('refresh');
    }, 100);
    while (this.labParametersArray.length) {
      this.labParametersArray.removeAt(0);
    }
    this.linkLabParameterService.searchLabTestV2(id).subscribe(
      (data) => {
        this.savedLabParameters = data.lab_assessments;
        if (this.savedLabParameters.length > 0) {
          this.savedLabParametersArrayForMoreParameters();
          this.isArray = true;
        } else {
          this.isArray = false;
        }
        setTimeout(() => {
          $('#labTestsPerameter').selectpicker();
          $('#labTestsPerameter').selectpicker('refresh');
          $('#copyParameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        setTimeout(() => {
          $('#labTestsPerameter').selectpicker();
          $('#labTestsPerameter').selectpicker('refresh');
          $('#copyParameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
    );
  }

  setParameterCode(labParameterId): any {
    // $('#labTestsPerameter').selectpicker('refresh');
    this.labParameter = this.labParameters.find(
      (t) => t.id === Number(labParameterId),
    );
    if (
      this.labParametersArray.value.find(
        (t) => t.id === Number(this.labParameter.id),
      )
    ) {
      toastr.error('This Parameter is Already Added');
      return;
    }
    this.editLabParameters();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 200);
    setTimeout(() => {
      $('#labTestsPerameter').val('');
      $('#labTestsPerameter').selectpicker('refresh');
      this.ref.markForCheck();
    }, 1000);
    this.ref.detectChanges();
  }
  get labParametersArray(): any {
    return this.LabParameterForm.get('parametersArray') as FormArray;
  }
  editLabParameters(): any {
    // if(this.labParametersArray.length >0) {
    // if (this.labParametersArray.value[0].parameter_code === '' || this.labParametersArray.value[0].parameter_code === null) {
    //   this.labParametersArray.removeAt(0);
    // }
    // }
    this.isArray = true;
    // for( let i=0; i<= this.labParametersArray.length; i++) {
    //   this.LabParameterForm.get(['parametersArray', i]).get('is_true').patchValue(false);
    // }
    this.labParametersArray.push(
      this.setLabParameters(this.labParameter, true),
    );
  }
  fetchAllLabPaarmeter(): any {
    this.labParameters = [];
    const labTests = '#labTestsPerameter';

    this.ngxLoader.start();
    this.labParameterService.fetch_all_parametersV2('', '', '', '').subscribe(
      (data) => {
        this.labParameters = data.lab_assessments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#labTestsPerameter').selectpicker();

          $('#labTestsPerameter').selectpicker('refresh');
        }, 500);
        if (this.savedLabParameters.length) {
          for (let j = 0; j < this.labParametersArray.length; j++) {
            setTimeout(() => {
              $('#parameters' + j).selectpicker();
              $('#parameters' + j).selectpicker('refresh');
            }, 200);
          }
        }
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  removeLabParameter(accessIndex): any {
    this.labParametersArray.removeAt(accessIndex);
    if (this.labParametersArray.length < 1) {
      this.isArray = false;
    }
  }
  // searchLabParmeter(): any {
  //   this.searchSubmitted = true;
  //   this.labParameter = this.labParameters.filter(t => t.id === code)[0];
  //   if (this.labParameter) {
  //     this.ngxLoader.start();
  //     this.isArray = true;
  //   }
  //   this.ngxLoader.stop();
  // }

  addLabParameterGroup(): any {
    return this.formBuilder.group({
      id: [''],
      title: [''],
      parameter_code: [''],
      is_active: [''],
      uom: [''],
      specimen: [''],
      result_type1: [''],
      result_type2: [''],
      parameter_type_new: [''],
      parameter_type: [''],
      lab_configuration_id: [''],
      is_add: [true],
      primary_id: [''],
      sort_order: [''],
    });
  }
  nameEventHander($event: any) {
    if ($event === 'true') {
      $('#addParameterModal').modal('show');
      if (this.isEditParameter === true) {
        this.updateOrCreateParameter();
        this.isEditParameter = false;
      }
      if (this.isAddParameterNew === true) {
        this.labParameters.push(this.returnObjectParameter);
        setTimeout(() => {
          $('#labTestsPerameter').selectpicker();
          $('#labTestsPerameter').selectpicker('refresh');
        }, 500);
        this.isAddParameterNew = false;
      }
      this.showParameterModalData = false;
    } else {
      this.showParameterModalData = true;
    }
  }
  ngOnInit(): void {
    $('#testDescription').summernote({
      height: 100,
      toolbar: [],
    });
    this.addEmptyParameters();
    $('#addMainParameter').on('hide.bs.modal', () => {
      if (this.modalHiden) {
        this.showParameterModalData = false;
        this.modalHiden = true;
      }
    });
    $('#addParameterModal').on('hide.bs.modal', () => {
      while (this.labParametersArray.length) {
        this.labParametersArray.removeAt(0);
        this.isArray = false;
      }
      setTimeout(() => {
        $('#labTestsPerameter').selectpicker('refresh');
        $('#copyParameter').selectpicker('refresh');
      }, 100);
      this.addLabTest = false;
    });
    $('#addLabModal').on('hide.bs.modal', () => {
      this.labTestSubmitted = false;
      //        this.labParametersArray.push(this.setLabParameters(this.addLabParameterGroup));
      this.clearModalValues();
    });
    this.fetchAllLabTest();
    this.fetchAllLabPaarmeter();
    this.LabTestForm = this.formBuilder.group({
      profile_name: ['', Validators.required],
      profile_code: [''],
      department_id: ['', Validators.required],
      report_type: ['Customized', Validators.required],
      status: [false],
      color: ['#000000'],
      lab_code: [''],
      send_out_id: [''],
      specimen_id: [''],
      reporting_time: [false],
      // parametersArray: this.formBuilder.array([this.addLabParameterGroup()])
    });

    this.fetchDepartments();
    this.getEhmList();
    this.fetchLabConfigurators();
    this.fetchLabConfiguratorsHeading();
    this.fetchAllLabTestWithoutPagination();

    setTimeout(() => {
      $('#reportTypePicker').selectpicker('refresh');
      $('#reportTypePicker').selectpicker();
    }, 500);
  }
  updateOrCreateParameter(): any {
    // if(this.returnUpdate === false) {
    // this.labParameters.push(this.returnObjectParameter);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('title')
      .patchValue(this.returnObjectParameter.title);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('id')
      .patchValue(this.returnObjectParameter.id);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('parameter_code')
      .patchValue(this.returnObjectParameter.parameter_code);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('is_active')
      .patchValue(this.returnObjectParameter.is_active);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('uom')
      .patchValue(this.returnObjectParameter.uom);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('specimen')
      .patchValue(this.returnObjectParameter.specimen);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('result_type1')
      .patchValue(this.returnObjectParameter.result_type1);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('result_type2')
      .patchValue(this.returnObjectParameter.result_type2);
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('parameter_type')
      .patchValue(this.returnObjectParameter.parameter_type);
    let parameterType = this.returnObjectParameter.parameter_type;
    if (parameterType === 'combo') {
      parameterType =
        this.returnObjectParameter.lab_assess_ranges[0]?.report_type_1 ===
        'decimal'
          ? 'Number'
          : this.returnObjectParameter.lab_assess_ranges[0]?.report_type_1;
    }
    this.LabParameterForm.get(['parametersArray', this.indexOfLabparameter])
      .get('parameter_type_new')
      .patchValue(parameterType);
    if (this.returnUpdate === false) {
      this.labParameters.push(this.returnObjectParameter);
    } else {
      const index = this.labParameters.findIndex((ob) => {
        return ob.id === this.returnObjectParameter.id;
      });
      this.labParameters.splice(index, 1, this.returnObjectParameter);
    }
    setTimeout(() => {
      $('#labTestsPerameter').selectpicker();
      $('#labTestsPerameter').selectpicker('refresh');
    }, 1000);
  }
  addEmptyParameters(): any {
    this.LabParameterForm = this.formBuilder.group({
      parametersArray: this.formBuilder.array([this.addLabParameterGroup()]),
    });
  }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentsByHospitalService.fetchSubDeptbyServicev2('lab', '').subscribe(
      (data) => {
        this.departments = data.departments;
        setTimeout(() => {
          $('#departmentPicker').selectpicker('refresh');
          $('#departmentPicker').selectpicker();
        }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEhmList(): any {
    this.ngxLoader.start();
    this.ehmService.indexV2().subscribe(
      (data) => {
        this.ehmList = data.send_outs;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchLabConfigurators(): any {
    this.ngxLoader.start();
    this.labService
      .getLabSpecificConfiguratorV2('specimen', 'true', '', '', '')
      .subscribe(
        (data) => {
          this.labConfigurations = data.lab_configurations;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchLabConfiguratorsHeading(): any {
    this.ngxLoader.start();
    this.labService
      .getLabSpecificConfiguratorV2('heading', 'true', '', '', '')
      .subscribe(
        (data) => {
          this.labHeadingConfigurations = data.lab_configurations;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.fetchAllLabTest();
  }
  loadPage(event): any {
    this.fetchAllLabTest();
  }

  searchLabTest(): any {
    this.page = 1;
    this.fetchAllLabTest();
  }

  fetchAllLabTest(): any {
    this.addLabTest = true;
    this.ngxLoader.start();
    this.pageDataList = [];
    this.labTestService
      .fetchAllLabTestV2(this.page, this.perPage, this.testCode, this.testName)
      .subscribe(
        (data) => {
          // this.testCode = '';
          // this.testName = '';
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          this.pageDataList = data.lab_investigations;
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          }, 500);
          // console.log(data);
          // const allTests = data.lab_investigations;
          // this.pageDataList = allTests;
          // this.listData = new MatTableDataSource(allTests);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchAllLabTestWithoutPagination(): any {
    //  this.addLabTest = true;
    this.ngxLoader.start();
    //    this.pageDataList = [];
    this.pageDataListForListing = [];
    this.labTestService.fetchAllLabTestV2('', '', '', '').subscribe(
      (data) => {
        // this.testCode = '';
        // this.testName = '';
        this.ngxLoader.stop();
        if (data.meta) {
          //      this.totalPages = data.meta.total_count;
        }
        this.pageDataListForListing = data.lab_investigations;
        // console.log(data);
        // const allTests = data.lab_investigations;
        // this.pageDataList = allTests;
        // this.listData = new MatTableDataSource(allTests);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  navigateToLink(labTestId): any {
    this.router.navigate(['/lab/link-lab-parameter/'], {
      queryParams: { id: labTestId },
    });
  }

  openTextArea(elements): any {
    this.labId = elements.id;
    // this.labTestDescription = {
    //   special_instructions: elements.special_instructions,
    //   protocol: elements.protocol,
    //   methodology: elements.methodology,
    //   performed_method: elements.performed_method,
    //   interpretation: elements.interpretation,
    //   comments: elements.comments,
    // };
    this.modalService.open(this.commentForm, { size: 'lg' });
    $(
      '.special_instructions, .protocol, .methodology, .performed_method, .interpretation, .comments',
    ).summernote({
      height: 100,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
    });
    $('#special_instructions').summernote(
      'code',
      elements.special_instructions,
    );
    $('#protocol').summernote('code', elements.protocol);
    $('#methodology').summernote('code', elements.methodology);
    $('#performed_method').summernote('code', elements.performed_method);
    $('#interpretation').summernote('code', elements.interpretation);
    $('#comments').summernote('code', elements.comments);
    this.labTestDescription.special_instructions_name =
      elements.special_instructions_name;
    this.labTestDescription.special_instructions_show_top =
      elements.special_instructions_show_top;
    this.labTestDescription.protocol_name = elements.protocol_name;
    this.labTestDescription.protocol_show_top = elements.protocol_show_top;
    this.labTestDescription.methodology_name = elements.methodology_name;
    this.labTestDescription.methodology_show_top =
      elements.methodology_show_top;
    this.labTestDescription.performed_method_name =
      elements.performed_method_name;
    this.labTestDescription.performed_method_show_top =
      elements.performed_method_show_top;
    this.labTestDescription.interpretation_name = elements.interpretation_name;
    this.labTestDescription.interpretation_show_top =
      elements.interpretation_show_top;
    this.labTestDescription.comments_name = elements.comments_name;
    this.labTestDescription.comments_show_top = elements.comments_show_top;
  }

  openSelectionArea(element): any {
    this.reportingTime = {
      type: element.reporting_type || 'minutes',
      value: element.reporting_value,
      id: element.id,
    };
    this.modalService.open(this.selectionForm);
  }
  addReportingTime(): any {
    if (
      this.reportingTime.value === null ||
      this.reportingTime.value === undefined ||
      this.reportingTime.value === ''
    ) {
      return;
    }
    if (this.reportingTime.type === '') {
      return;
    }
    this.ngxLoader.start();
    this.labTestService
      .addReportingTimeV2(
        this.reportingTime.type,
        this.reportingTime.value,
        this.reportingTime.id,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.selectionForm);
          this.ngxLoader.stop();
          this.fetchAllLabTest();
          toastr.success('Successfully saved lab test reporting');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  //  openAddLabModal() :any{
  //    $('#addLabModal').modal('show');
  // //   this.labParametersArray.push(this.setLabParameters(''));
  //
  //  }
  checkIfArrayIsUnique(myArray): any {
    const sortArray = [];
    for (let i = 0; i < myArray.length; i++) {
      sortArray.push(myArray[i].sort_order);
    }
    return sortArray.length === new Set(sortArray).size;
  }
  refreshPicker(): any {
    setTimeout(() => {
      $('#labTestsPerameter').selectpicker();
      $('#labTestsPerameter').selectpicker('refresh');
      $('#labConfig').selectpicker();
      $('#labConfig').selectpicker('refresh');
      $('#parameters').selectpicker();
      $('#parameters').selectpicker('refresh');
    }, 500);
  }

  newLabTest(): any {
    setTimeout(() => {
      $('#departmentPicker').selectpicker('refresh');
      $('#reportTypePicker').selectpicker('refresh');
      $('#sendout').selectpicker('refresh');
      $('#Specimen').selectpicker('refresh');
    }, 1000);
    this.labTestSubmitted = true;

    if (this.LabTestForm.invalid) {
      return;
    }

    this.ngxLoader.start();
    this.labTestService.createV2(this.LabTestForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Successfully saved new lab test');
        $('#addLabModal').modal('hide');
        this.isOpenModal = false;
        this.fetchAllLabTest();
        this.clearModalValues();
        this.pageDataListForListing.push(data.lab_investigation);
        setTimeout(() => {
          $('#copyParameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  afterUpdateSuccess(data, check): any {
    $('#addLabModal').modal('hide');
    this.isOpenModal = false;
    this.fetchAllLabTest();
    this.addLabTest = true;
    if (data && !check) {
      this.pageDataListForListing.splice(
        this.toUpdateLabTest,
        1,
        data.lab_investigation,
      );
    } else {
      this.pageDataListForListing.push(data.lab_investigation);
    }
  }

  clearModalValues(): any {
    this.LabTestForm.reset();
    this.labTestSubmitted = false;
    this.LabTestForm.patchValue({
      profile_name: '',
      profile_code: '',
      department_id: '',
      report_type: 'Customized',
      status: false,
      color: '#000000',
      lab_code: '',
      send_out_id: '',
      specimen_id: '',
      price: 0,
      reporting_time: false,
    });
    $('#departmentPicker').selectpicker('refresh');
    $('#sendout').selectpicker('refresh');
    $('#specimen').selectpicker('refresh');
  }

  get f(): any {
    return this.LabTestForm.controls;
  }
  addLabDescription(): any {
    this.labTestDescription.special_instructions = $(
      '#special_instructions',
    ).summernote('code');
    this.labTestDescription.protocol = $('#protocol').summernote('code');
    this.labTestDescription.methodology = $('#methodology').summernote('code');
    this.labTestDescription.performed_method =
      $('#performed_method').summernote('code');
    this.labTestDescription.interpretation =
      $('#interpretation').summernote('code');
    this.labTestDescription.comments = $('#comments').summernote('code');
    this.ngxLoader.start();
    console.log('labTestDescription', this.labTestDescription);
    this.labTestService
      .addLabTestDescriptionFunV2(this.labTestDescription, this.labId)
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.commentForm);
          this.ngxLoader.stop();
          this.fetchAllLabTest();
          toastr.success('Successfully saved lab test description');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateTest(testID: any, value: any, index): any {
    this.updateId = '';
    this.sharedService.sendUpdateLabData(testID);
    this.toUpdateLabTest = index;
    // if (this.labParametersArray.value[0].parameter_code === '' || this.labParametersArray.value[0].parameter_code === null) {
    //   this.labParametersArray.removeAt(0);
    // }
    this.updateId = testID;
    this.addLabTest = false;
    //     for (let i = 0; i < value.parametersArray.length; i++) {
    //       this.labParametersArray.push(this.setLabParameters(value.parametersArray[i]));
    // //      this.labParametersArray.push(this.setLabParameters(this.addLabParameterGroup));
    //
    //     }
    $('#addLabModal').modal('show');
    this.isOpenModal = true;
    this.IsPreviouslyEdit = true;
  }
  addLabParameter(): any {
    this.isUpdateParameter = false;
    this.isUpdated = '';
    // this.fetchAllLabConfigurators();
    $('#addMainParameter').modal('show');
    this.showParameterModalData = true;

    //    this.labParameter
  }
  editParameter(id, i): any {
    this.indexOfLabparameter = i;

    this.isUpdateParameter = true;
    this.isUpdated = id.value.id;
    this.assessInvestigationID =
      this.savedLabParameters[i].assess_investigation_id;

    $('#addMainParameter').modal('show');
    this.showParameterModalData = true;

    // window.open('lab/update-parameter-details?id=' + id.value.id);
  }
  // sendReportType(event, id, i): any {
  //   if ($('#reportTypePcr' + id).is(':checked') === true || $('#reportTypeSolana' + id).is(':checked') === true) {
  //     $('#reportType' + id).prop('checked', false);
  //     return;
  //   } else if (
  //     $('#reportType' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === false &&
  //     $('#reportTypeSolana' + id).is(':checked') === false
  //   ) {
  //     this.report_type = 'Customized';
  //     this.pageDataList[i].report_type = 'Customized';
  //   } else if (
  //     $('#reportType' + id).is(':checked') === true && $('#reportTypeSolana' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === false
  //   ) {
  //     this.report_type = 'Culture';
  //     this.pageDataList[i].report_type = 'Culture';
  //   }
  //   this.sendReportTypes(id);
  // }
  // sendReportTypePcr(event, id, i): any {
  //   if ($('#reportType' + id).is(':checked') === true || $('#reportTypeSolana' + id).is(':checked') === true) {
  //     $('#reportTypePcr' + id).prop('checked', false);
  //     return;
  //   } else if (
  //     $('#reportType' + id).is(':checked') === false && $('#reportTypeSolana' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === true
  //   ) {
  //     this.report_type = 'PCR';
  //     this.pageDataList[i].report_type = 'PCR';
  //   } else if (
  //     $('#reportType' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === false &&
  //     $('#reportTypeSolana' + id).is(':checked') === false
  //   ) {
  //     this.report_type = 'Customized';
  //     this.pageDataList[i].report_type = 'Customized';
  //   }
  //   this.sendReportTypes(id);
  // }
  // sendReportTypeSolana(event, id, i): any {
  //   if ($('#reportType' + id).is(':checked') === true || $('#reportTypePcr' + id).is(':checked') === true) {
  //     // $('#reportTypePcr' + id).prop('checked', false);
  //     $('#reportTypeSolana' + id).prop('checked', false);
  //     return;
  //   } else if (
  //     $('#reportType' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === false && $('#reportTypeSolana' + id).is(':checked') === true
  //   ) {
  //     this.report_type = 'Solana';
  //     this.pageDataList[i].report_type = 'Solana';
  //   } else if (
  //     $('#reportType' + id).is(':checked') === false &&
  //     $('#reportTypePcr' + id).is(':checked') === false && $('#reportTypeSolana' + id).is(':checked') === false
  //   ) {
  //     this.report_type = 'Customized';
  //     this.pageDataList[i].report_type = 'Customized';
  //   }
  //   this.sendReportTypes(id);
  // }
  sendReportTypes(value, id, i): any {
    this.report_type = value;
    this.pageDataList[i].report_type = value;
    this.ngxLoader.start();
    this.labTestService.sendReporType(this.report_type, id).subscribe(
      (data) => {
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  showPreviousResults(event, id): any {
    if ($('#previous_result' + id).prop('checked') === true) {
      this.previous_result = true;
    } else {
      this.previous_result = false;
    }
    this.ngxLoader.start();
    this.labTestService.previousResult(this.previous_result, id).subscribe(
      (data) => {
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
