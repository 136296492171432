import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class HospitaldoctorService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/hospital_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/hospital_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  doctorShare(empId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/detail_doctor_share_against_doctor/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&employee_id=' +
          empId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getHospitalDoctorsByName(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_hospital_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getHospitalDoctorsByNameV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_hospital_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchIpdConsultationDoctor(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_ipd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchIpdConsultationDoctorV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_ipd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchOpdConsultationDoctor(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_opd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchRadiologyTemplatesDoctorslist(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_opd_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python Start
  fetchOpdConsultationDoctorV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_opd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchOpdConsultationDoctorShareV2(doctor): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_opd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          doctor.type +
          '&query=' +
          doctor.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End
  // Python Start
  getEmployeesForCardiac(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/list_all_employees/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End

  fetchDepartmentDoctor(department_id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/department_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDepartmentDoctorList(department_id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_department_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDepartmentDoctorListV2(department_id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_department_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          department_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  allocateDepartmentToDoctor(doctor, department) {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/users/assign_department_to_doctor?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          user_id: doctor,
          doctor_department_id: department,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // lab users
  fetchAllUsers(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllUsersNames(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/all_users?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllUsersNamesV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/new_all_users/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchUserById(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/specific_user?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          user_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchUserByIdv2(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/specific_user?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          user_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  assignDepartmentToUsers(department_id, user_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/departments/assign_department_to_user?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          user_id,
          department_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  assignDepartmentToUsersv2(department_id, user_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/assign_department_to_user/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          user_id,
          department_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitMedicine(medicines): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/any?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medicines,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateMedicine(medicines, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/any?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medicines,
          id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicines(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/any?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeDepartmentToUsers(department_id, user_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/departments/remove_user_department?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          user_id,
          department_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeDepartmentToUsersv2(department_id, user_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/remove_user_department/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          user_id,
          department_id,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  doctorGroups(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_groups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  doctorGroupsV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/doctor_groups/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchRefDoctorsDoctor(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_referring_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python Start
  fetchRefDoctorsDoctorV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_referring_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End
  allActiveRefPerDrs(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/referring_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  allActiveUsers(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_employees_by_type_optimize/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
